import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const BlackCard = ({ icon, title, content }) => {
    return (
        <Card 
            sx={{ 
                background: 'linear-gradient(135deg, #080808, #333)', // Solid shiny gradient effect
                color: 'white', 
                padding: 2, 
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
                position: 'relative',
                overflow: 'hidden',
                borderRadius: 2,
                // Additional highlights
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(255, 255, 255, 0.1)', // Light highlight for shine
                    mixBlendMode: 'overlay', // Blend mode for shine effect
                    pointerEvents: 'none',
                }
            }}
        >
            <CardContent>
                {/* Render the icon passed as a prop */}
                <Box textAlign="center" mb={2}>
                    {icon && React.cloneElement(icon, { sx: { fontSize: 40, color: '#00aa40' } })}
                </Box>

                {/* Green header with slim font */}
                <Box textAlign="center" mb={2}>
                    <Typography variant="h6" component="div" sx={{ color: '#00aa40', fontWeight: 300 }}>
                        {title}
                    </Typography>
                </Box>

                {/* White content with slim font */}
                <Box textAlign="center">
                    <Typography variant="body2" sx={{ color: '#aaaaaa', fontWeight: 300 }}>
                        {content}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default BlackCard;
