import React, { useState } from 'react';
import './App.css';
import { AppBar, Toolbar, Box, Link, Button, Typography } from '@mui/material';
import { Container, Grid } from '@mui/material';
import { TextField } from '@mui/material';
import Navbar from './components/Navbar';

function CreateEmail() {
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [codeSent, setCodeSent] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Mock sending verification code (replace this with actual API call)
        const generatedCode = Math.floor(100000 + Math.random() * 900000).toString(); // Generate a 6-digit code
        console.log(`Verification code sent to ${email}: ${generatedCode}`);
        
        // Simulate sending the verification code (you might want to replace this with an API request)
        // await sendVerificationCode(email, generatedCode); // Uncomment and implement this function

        // Set state to indicate the code was sent
        setVerificationCode(generatedCode);
        setCodeSent(true);
        
        // Clear form fields
        setEmail('');
        
    };

    return (
        <div style={styles.background}>
            {/* Transparent Navbar with Logo and Nav Links */}
            <Navbar />
            {/* Main content */}
            <Container style={{ marginTop: '1rem', color: '#ffffff' }}>
                <Grid container spacing={4} style={{ fontWeight: 100, textAlign: 'left' }}>
                    <Grid item xs={12} md={6}>
                        <p className='very-big-caption'>Create Your <label className='mart-green'>Email</label> Account Today!</p>
                        <p className='small-caption'>Start setting up your account by verifying your email. This helps in security and account recovery</p>
                        <br />
                        <img src='./email.png' alt='Slide Holder' style={{ maxWidth: '30%', height: 'auto', borderRadius: '10px' }} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box sx={{ mt: 5 }}>
                            <Typography variant="h6" component="h1" gutterBottom>
                                Email Details
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    margin="normal"
                                    required
                                    InputProps={{
                                        style: { backgroundColor: 'white' }, // Set background color to white
                                    }}
                                />
                                <Link to="/verify_email">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    sx={{ mt: 2, p: 2, width: '100%' }}
                                >
                                    Send Verification Code
                                </Button>
                                </Link>
                            </form>

                            {codeSent && (
                                <Typography variant="body1" color="success.main" sx={{ mt: 2 }}>
                                    Verification code has been sent to {email}. Please check your inbox!
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

const styles = {
    background: {
        backgroundImage: `url(https://www.transparenttextures.com/patterns/asfalt-dark.png)`,
        backgroundColor: '#121212', // Fallback color for the dark theme
        minHeight: '100vh',
        backgroundSize: 'cover',
    },
};

export default CreateEmail;
