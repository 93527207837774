import React, { useState } from 'react';
import './App.css';
import { AppBar, Toolbar, Box, Link, Button, Typography } from '@mui/material';
import { Container, Grid } from '@mui/material';
import { TextField } from '@mui/material';
import Navbar from './components/Navbar';

function VerifyEmail() {
    const [email, setEmail] = useState('');
    const [verification_code, setVerificationCode] = useState('');
   

    const handleSubmit = (event) => {
        event.preventDefault();
        
       
        
        // Handle successful submission logic here
        console.log({
            email,
            verification_code
        });

        // Clear form fields
        setEmail('');
        setVerificationCode('');
        
    };

    return (
        <div style={styles.background}>
            {/* Transparent Navbar with Logo and Nav Links */}
            <Navbar />
            {/* Main content */}
            <Container style={{ marginTop: '1rem', color: '#ffffff' }}>
                <Grid container spacing={4} style={{ fontWeight: 100, textAlign: 'left' }}>

                    <Grid item xs={12} md={6}>
                        <p className='very-big-caption'>Create Your <label className='mart-green'>Email</label> Account Today!</p>
                        <p className='small-caption'>Start seting up your account by verifying you email. This helps in security and account recovery</p>
                        <br />
                        <img src='./verify.png' alt='Slide Holder' style={{ maxWidth: '40%', height: 'auto', borderRadius: '10px' }} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box sx={{ mt: 5 }}>
                            <Typography variant="h6" component="h1" gutterBottom>
                                Email Details
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    margin="normal"
                                    required
                                    InputProps={{
                                        style: { backgroundColor: 'white' }, // Set background color to white
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Verification Code"
                                    variant="outlined"
                                    value={verification_code}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                    margin="normal"
                                    required
                                    InputProps={{
                                        style: { backgroundColor: 'white' }, // Set background color to white
                                    }}
                                />
                               
                              
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    sx={{ mt: 2, p:2, width: '100%' }}
                                >
                                    Submit
                                </Button>
                            </form>
                        </Box>
                      
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

const styles = {
    background: {
        backgroundImage: `url(https://www.transparenttextures.com/patterns/asfalt-dark.png)`,
        backgroundColor: '#121212', // Fallback color for the dark theme
        minHeight: '100vh',
        backgroundSize: 'cover',
    },
};

export default VerifyEmail;
