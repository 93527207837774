import React from 'react';
import { AppBar, Toolbar, Box, Button, Link, Switch, Container } from '@mui/material';

const Navbar = () => {
    const styles = {
        transparentAppBar: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
        logo: {
            height: '40px', // Adjust the height of the logo
            marginRight: '16px',
        },
        navLinks: {
            display: 'flex',
            gap: '24px', // Space between the links
        },
        link: {
            fontSize: '1rem',
            '&:hover': {
                textDecoration: 'underline', // Add underline on hover
            },
        },
    };

    return (
        <Container style={{ marginTop: '0rem', color: '#fff' }}>
            <AppBar sx={{ display: { xs: 'none', sm: 'block' } }} position="static" style={styles.transparentAppBar}>
                <Toolbar>
                    <Box component="img" src="/logo.png" alt="Logo" sx={styles.logo} />

                    {/* Navigation Links */}
                    <Box sx={styles.navLinks}>
                        <Link href="#about" color="inherit" underline="none" sx={styles.link}>
                            About
                        </Link>
                        <Link href="#privacy" color="inherit" underline="none" sx={styles.link}>
                            Privacy Policy
                        </Link>
                        <Link href="#support" color="inherit" underline="none" sx={styles.link}>
                            Products Support
                        </Link>
                    </Box>

                    {/* Push Login to the right */}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Switch color="success" label="dark mode" />
                        <Link to="./create_email"><Button variant="contained" color="success">Sign up</Button></Link>
                        <Button variant="outlined" color="success">Login</Button>
                    </Box>
                </Toolbar>
            </AppBar>
        </Container>
    );
};

export default Navbar;
