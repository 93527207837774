import React, { useState } from 'react';
import './App.css';
import { AppBar, Toolbar, Box, Link, Button, Typography } from '@mui/material';
import { Container, Grid } from '@mui/material';
import { TextField } from '@mui/material';
import Navbar from './components/Navbar';

function CreateAccount() {
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        
        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }
        
        // Handle successful submission logic here
        console.log({
            fullName,
            phoneNumber,
            password,
        });

        // Clear form fields
        setFullName('');
        setPhoneNumber('');
        setPassword('');
        setConfirmPassword('');
        setError('');
    };

    return (
        <div style={styles.background}>
            {/* Transparent Navbar with Logo and Nav Links */}
            <Navbar />
            {/* Main content */}
            <Container style={{ marginTop: '1rem', color: '#ffffff' }}>
                <Grid container spacing={1} style={{ fontWeight: 100, textAlign: 'left' }}>

                    <Grid item xs={12} md={6}>
                        <p className='very-big-caption'>Create Your <label className='mart-green'>Vendor</label> Account Today!</p>
                        <p className='small-caption'>Let us create unbeatable memories or customers</p>
                        <br />
                        <img src='./vendor.png' alt='Slide Holder' style={{ maxWidth: '40%', height: 'auto', borderRadius: '10px' }} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box sx={{ mt: 5 }}>
                            <Typography variant="h6" component="h1" gutterBottom>
                                Personal Details
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    fullWidth
                                    label="Full Name"
                                    variant="outlined"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                    margin="normal"
                                    required
                                    InputProps={{
                                        style: { backgroundColor: 'white' }, // Set background color to white
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    variant="outlined"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    margin="normal"
                                    required
                                    InputProps={{
                                        style: { backgroundColor: 'white' }, // Set background color to white
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Password"
                                    type="password"
                                    variant="outlined"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    margin="normal"
                                    required
                                    InputProps={{
                                        style: { backgroundColor: 'white' }, // Set background color to white
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Confirm Password"
                                    type="password"
                                    variant="outlined"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    margin="normal"
                                    required
                                    InputProps={{
                                        style: { backgroundColor: 'white' }, // Set background color to white
                                    }}
                                />
                                {error && <Typography color="error">{error}</Typography>}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    sx={{ mt: 2, p:2, width: '100%' }}
                                >
                                    Submit
                                </Button>
                            </form>
                        </Box>
                      
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

const styles = {
    background: {
        backgroundImage: `url(https://www.transparenttextures.com/patterns/asfalt-dark.png)`,
        backgroundColor: '#121212', // Fallback color for the dark theme
        minHeight: '100vh',
        backgroundSize: 'cover',
    },
};

export default CreateAccount;
