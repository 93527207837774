//import React, { useState } from 'react';
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './App.css';
import { AppBar, Toolbar, Box, Link, Button, Typography } from '@mui/material';
import { Container, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt'; // Import the camera icon
import Navbar from './components/Navbar';

function CreateBusiness() {
    const [category, setCategory] = useState('');
    const [business_name, setBusinessName] = useState('');
    const [email, setEmail] = useState('');
    const [slogan, setSlogan] = useState('');
    const [year_established, setYearOfEstablishment] = useState('');
    const [logo, setLogo] = useState(null);
    const [error, setError] = useState('');
    const [allCategories, setAllCategory] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('http://localhost:8081/category').then(res => setAllCategory(res.data))
            .catch(err => console.log(err));
    }, [])
    ////for searcheable category
    const categoryOptions = allCategories.map((category) => ({
        value: category.id,
        label: category.name,
    }));

    const handleCategoryChange = (selectedOption) => {
        setCategory(selectedOption ? selectedOption.value : '');
    };
    //##############################


    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLogo(URL.createObjectURL(file));
        }
    };

    // Create a ref for the hidden file input
    const fileInputRef = React.useRef();

    // Handle button click to trigger file input
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    function handleSubmit(event) {
        event.preventDefault();
        axios.post('http://localhost:8081/createBusiness', { email, category, business_name, slogan, year_established,fileInputRef }).then(res => {
            console.log(res);
            navigate(-1);
        }).catch(err => console.log(err));
    }

    return (
        <div style={styles.background}>
            <Navbar />
            <Container style={{ marginTop: '1rem', color: '#ffffff' }}>
                <Grid container spacing={4} style={{ fontWeight: 100, textAlign: 'left' }}>
                    <Grid item xs={12} md={6}>
                        <p className='very-big-caption'>Create Your <label className='mart-green'>Business</label> Account Today!</p>
                        <p className='small-caption'>Empowering Your Business Through Innovation: Transforming Challenges into Opportunities, with Solutions Tailored to Drive Your Success.</p>
                        <br />
                        <Grid container spacing={2}> {/* Add container and spacing for better layout */}
                            <Grid item xs={6} md={6}>
                                <img
                                    src='./business.png'
                                    alt='Slide Holder'
                                    style={{ maxWidth: '100%', height: 'auto', borderRadius: '10px' }} // Use 100% width for responsiveness
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Box
                                    sx={{
                                        mt: 2,
                                        width: '100%',
                                        maxHeight: '200px',
                                        border: '2px dashed #ccc',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: logo ? 'transparent' : '#f0f0f0'
                                    }}
                                >
                                    {logo ? (
                                        <img
                                            src={logo}
                                            alt="Uploaded Logo"
                                            style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '5px' }}
                                        />
                                    ) : (
                                        <Typography variant="body2" color="textSecondary">Your Business Logo</Typography>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box sx={{ mt: 5 }}>
                            <Typography variant="h6" component="h1" gutterBottom>
                                Business Details
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    fullWidth
                                    select
                                    label="Category"
                                    variant="outlined"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    margin="normal"
                                    required
                                    InputProps={{
                                        style: { backgroundColor: 'white' },
                                    }}
                                >
                                    {categoryOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    margin="normal"
                                    required
                                    InputProps={{
                                        style: { backgroundColor: 'white' },
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Business Name"
                                    variant="outlined"
                                    value={business_name}
                                    onChange={(e) => setBusinessName(e.target.value)}
                                    margin="normal"
                                    required
                                    InputProps={{
                                        style: { backgroundColor: 'white' },
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Business Slogan"
                                    type="text"
                                    variant="outlined"
                                    value={slogan}
                                    onChange={(e) => setSlogan(e.target.value)}
                                    margin="normal"
                                    required
                                    InputProps={{
                                        style: { backgroundColor: 'white' },
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Year of Establishment"
                                    type="number"
                                    variant="outlined"
                                    value={year_established}
                                    onChange={(e) => setYearOfEstablishment(e.target.value)}
                                    margin="normal"
                                    required
                                    InputProps={{
                                        style: { backgroundColor: 'white' },
                                    }}
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleLogoChange}
                                    ref={fileInputRef} // Assign ref to the input
                                    style={{ display: 'none' }} // Hide the input
                                />
                                <Button
                                    variant="contained"
                                    color="success"
                                    sx={{ mt: 2, p: 2, width: '100%' }}
                                    startIcon={<CameraAltIcon />} // Use the camera icon
                                    onClick={handleButtonClick} // Trigger file input
                                >
                                    Upload Logo
                                </Button>

                                {/* Placeholder or uploaded logo */}


                                {error && <Typography color="error">{error}</Typography>}

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    sx={{ mt: 2, p: 2, width: '100%' }}
                                >
                                    Submit
                                </Button>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

const styles = {
    background: {
        backgroundImage: `url(https://www.transparenttextures.com/patterns/asfalt-dark.png)`,
        backgroundColor: '#121212',
        minHeight: '100vh',
        backgroundSize: 'cover',
    },
};

export default CreateBusiness;
