import React, { useState, useRef } from 'react';
import './App.css';
import { AppBar, Toolbar, Box, Link, Button, Typography } from '@mui/material';
import { Container, Grid, Paper } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Card, CardMedia, CardContent } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import StorefrontIcon from '@mui/icons-material/Storefront'; // Markets around me
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'; // Order now
import CategoryIcon from '@mui/icons-material/Category'; // Categories
import WhatshotIcon from '@mui/icons-material/Whatshot'; // Trending now
import LocalOfferIcon from '@mui/icons-material/LocalOffer'; // Deals and offers
import LocationOnIcon from '@mui/icons-material/LocationOn'; // Enable location
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StarRateIcon from '@mui/icons-material/StarRate';
import ChatIcon from '@mui/icons-material/Chat';
import WorkIcon from '@mui/icons-material/Work';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Switch from '@mui/material/Switch';

import { TextField, IconButton, InputAdornment, Fab } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BlackCard from './components/BlackCard';
import Navbar from './components/Navbar';
const label = { inputProps: { 'aria-label': 'Switch demo' } };




function CreateVendor() {


    return (
        <div style={styles.background}>
            {/* Transparent Navbar with Logo and Nav Links */}
            <Navbar/>
            {/* Main content */}
            <Container style={{ marginTop: '1rem', color: '#ffffff' }}>
                <Grid container spacing={1} style={{ fontWeight: 100, textAlign: 'left' }}>

                    <Grid item xs={12} md={3} >
                        <p className='big-caption'>Create Your <label class='mart-green'>User</label> Account Today!</p>
                        <p className='small-caption'>Let us create unbeatable memories for our customers,
                            join martultimate and take your business to the next level</p>
                        <br />
                        <button className='white-button'>Create your business account <ArrowForwardIcon /></button>
                        <p className='small-caption mart-green'><LocationOnIcon /> Enable live location </p>
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <img src='./slide_holder_1.jpg' alt='Slide Holder' style={{ maxWidth: '100%', height: 'auto', borderRadius: '10px' }} />
                        </Box>
                    </Grid>


                </Grid>

                <section>
                    <Grid container spacing={4}  style={{ fontWeight: 100, textAlign: 'center' }}>
                        <Grid item xs={12} md={12} >
                            <p className='big-caption'>Why Sell with Mart Ultimate</p>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <BlackCard 
                            icon={<ChatIcon />}
                            title={'Reach More Customers'}
                            content={'Expand your business with access to a vast customer base, boosting your visibility and sales across the platform. With targeted marketing, personalized recommendations, and advanced analytics, you wll connect with the right customers and optimize your growth potential'}
                             />

                        </Grid>
                        <Grid item xs={12} md={4} >
                        <BlackCard 
                            icon={<WorkIcon />}
                            title={'Seamless Selling Experience'}
                            content={'Enjoy easy-to-use tools for managing inventory, processing orders, and tracking performance, all in one place. With real-time updates, automated notifications, and integrated payment options, you can streamline your workflow and focus on growing your business without the hassle.'}
                             />
                        </Grid>
                        <Grid item xs={12} md={4} >
                        <BlackCard 
                            icon={<CameraAltIcon />}
                            title={'AI Designed for Your Success'}
                            content={' Experience the power of a custom-trained AI built specifically for your business needs. Whether it is providing personalized customer recommendations, optimizing your inventory management, or streamlining communication, this AI is tailored to help you operate more efficiently. '}
                             />
                
                        </Grid>
                    </Grid>


                </section>
            </Container>

        </div>
    );
};

const styles = {
    background: {
        backgroundImage: `url(https://www.transparenttextures.com/patterns/asfalt-dark.png)`,
        backgroundColor: '#121212', // Fallback color for the dark theme
        minHeight: '100vh',
        backgroundSize: 'cover',
    },
    
};

export default CreateVendor;
